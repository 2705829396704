<template>
    <div ref="chartContainer" class="chart" style="height: 100%; position: relative">
        <!-- <div style="position: absolute; top: -10%;left: 10%; z-index: 2;">
            <button @click="exportXlsx()"> Xlsx</button>
            <button @click="exportSvg()"> Svg</button>
        </div> -->
    </div>
    <ExportSettingsDialog 
        @exportToSvg="exportToSvgEmit"
    
    />
</template>

<script>
/* eslint-disable */

import Anychart from 'anychart'
import ExportSettingsDialog from './ExportSettingsDialog.vue'
import './AnychartThemes/market_forecast.js'

export default {
    props: [
            'options', 
            'Anychart', 
            'years', 
            'data',
            'total', 
            'segmentationItems', 
            'output', 
            'segmentationVersusTitles',
            'topicName',
            'segmentationName1',
            'segmentationName2',
            'valueDisplayOption',
            'segmentation1ItemsName',

            'exportToWord',
            'index1',

            // Length
            'toChartSegmItemsLength',
            'countLength',
            'chartIndex'
        ],
    emits: ['next-step','addChartsForExportWord','finishLoading'],
    name: 'VueAnychartVersus',
    components:{
        ExportSettingsDialog
    },
    data() {
        return {
            chart: null,
            column: null,
        }
    },
    mounted() {
        // if (!this.chart && this.options) {
        //     this.init();
        // }
        this.init();
        // this.$emit('next-step') //if we have more charts on this page enable this code
        
        // VALIDATE IF CAME FROM EXPORT TO WORD 
        // VALIDATE IF IS LAST CHART THEN END LOADING
        if(this.exportToWord){
            this.addChartsForWord();
            if(this.toChartSegmItemsLength -1 === this.index1 && this.countLength -1 === this.chartIndex ){
                this.endLastStepForExportToWord()
            }
        }
    },

    computed: {
        topic() {
            return this.$store.getters.obtainTopic;
        },
    },
    methods: {
        

        removeSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeSeriesAt(0);
            }
        },
        removeAllSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeAllSeries();
            }
        },
        addSeries(data) {
            this.delegateMethod('addSeries', data);
        },
        delegateMethod(name, data) {
            if (!this.chart) {
                warn(`Cannot call [$name] before the chart is initialized. Set prop [options] first.`, this);
                return
            }

            return this.chart[name](data)
        },

        init(){
            let _Anychart = this.Anychart || Anychart; 
            this.chart = _Anychart; 

            // construct data for set 

            const years = this.years;
            const dataValues = this.data;
            const segmItems = this.segmentationItems;
            let total = [...this.total];
            total.shift();
            total.pop();

            // format ToParse
            for(let i = 0; i < total.length; i++){
                const num  = parseFloat(total[i]);
                total[i]= isNaN(num) ? total[i]  : num;
            }

            let forSetData = []; 

            years.map((year, index)=>{
                let Array = []
                Array.push(year);
                dataValues[index].map(data=>{
                    Array.push(data);
                })
                Array.push(total[index])
                forSetData.push(Array)
            })

            // Set Data
            let dataSet = this.chart.data.set(forSetData);

            // create Bar Chart
            this.column = this.chart.column();

            // APPLY THEME
            this.chart.theme('marketForecast');

            // LICENSE KEY
            this.chart.licenseKey('marketforecast.com-f857f242-9dccc95c'); 

            // CREDITS
            const currentYear = new Date().getFullYear();
            this.column
                .credits()
                .enabled(true)
                .url('https://www.marketforecast.com')
                .logoSrc('https://www.marketforecast.com/favicon.ico')
                .text(
                    `© Market Forecast ${currentYear}`
                );

            // force chart to stack values by Y Scale

            this.column.yScale().stackMode('value');
            
            // TITLE
            // hide stageTitle Title
            // this.column
            //     .title()
            //     .enabled(true)
            //     .useHtml(true)
            //     .padding([0, 0, 10, 0])
            //     .text(
            //         `<span style="font-size: 16px;font-weight:bold;">${this.segmentation1ItemsName} Market Forecast to ${ years[years.length -1 ]} by ${this.segmentationName2}</span>` +
            //         '<br/><span style="color:#929292; font-size: 12px;">' +
            //         `Market Forecast ${years[0]} - ${years[years.length - 1]}</span>`
            //     );
            
            // SET CHART MARGIN SETTINGS
            // this.column.padding(20, 20, 10,20);
            this.column.padding(20, 5, 5, 5);

            // GRID SETTINGS
            this.column
                .yGrid(true)
                .xGrid(false)
                .xMinorGrid(false)
                .yMinorGrid(true);

            // SET CHART LEGEND SETTINGS
            this.column.legend().enabled(true).padding({top:10, bottom:30})
            this.column.legend().position("bottom");
            this.column.legend().itemsLayout("horizontal-expandable")

            this.column.yAxis(0)
                .title("Market size in US$ "+ this.valueDisplayOption)
                .title().padding(20)
                .fontColor("#385D8A") //Change color of Y-axis title

            this.column.yAxis(0)
                .labels().format(
                    function(){
                        return Anychart.format.number(this.value, {
                            groupsSeparator:','
                        });
                    }
                )
            
            // create a line series for total market forecast 
            // create this line as first line - this will make the market a circle
            // let totalSeriesData = dataSet.mapAs({x:0, value:forSetData[0].length - 1}) //get length of first index  
            // let totalSeriesForecast = this.column.spline(totalSeriesData);
            // totalSeriesForecast.name('Total Market Forecast'); // Rename the totalSeriesForecast

            // // Add labels to totalSeriesForecast with format 
            // totalSeriesForecast.labels()
            //     .enabled(true)
            //     .format(
            //         function(){
            //             return Anychart.format.number(this.value,{
            //                 groupsSeparator:','
            //             });
            //         }
            //     )
            //     .anchor('center-bottom')
            //     .offsetY(10)
            //     .padding(5)
            //     .background().enabled(true).fill("#FFFFFF"); //Add Background color 
            
            // HELPER FUNCTION TO SETUP LABEL SETTINGS FOR ALL SERIES
            let setupSeriesLabels = function(series, name){
                series.name(name).stroke('1 #fff 1');
                series.hovered().stroke('1 #fff 1');

                series.normal().stroke("#ffffff", 1, "", "");
                series.hovered().stroke("#ffffff", 1, "", "");
                series.selected().stroke("#ffffff", 1, "", "");

                // Set opacity for different states,
                series.normal().fill(function(){
                    return this.sourceColor + ' 1'; // Keep color with opacity 1
                });

                series.hovered().fill(function(){
                    return this.sourceColor + ' 0.8'; // Keep color with opacity 0.8
                });

                series.selected().fill(function(){
                    return this.sourceColor + ' 0.6'; // Keep color with opacity 0.6
                });
            }

            // create Series with mapped Data
            // set dinamic mapData , create dinamic series

            segmItems.forEach((item, index)=>{
                const segmSeriesData = dataSet.mapAs({x:0, value:index + 1})
                const series = this.column.column(segmSeriesData)
                setupSeriesLabels(series, item)
            })

            // SET GLOBAL TOOLTIP styles
            this.column.tooltip().title().fontColor('#FFFFFF'); // SET TOOLTIP TEXT COLOR
            this.column.tooltip().title().fontSize(14); // SET TOOLTIP FONT SIZE
            this.column.tooltip().fontColor("#FFFFFF"); // SET TOOLTIP TEXT COLOR
            this.column.tooltip().fontSize(12); // SET TOOLTIP FONT SIZE
            this.column.tooltip().padding(10); // SET TOOLTIP PADDING

            // Customize the tooltip title and content 

            const valueDisplayOption = this.valueDisplayOption

            this.column.tooltip()
                .titleFormat(function () {
                    return 'Market Forecast: ' + this.points[0].x;
                })
                .format(
                    function(){
                        return this.seriesName+' : ' + Anychart.format.number(this.value, {
                            groupsSeparator:',',
                            decimalSeparator: '.'
                        })+` US$ ${valueDisplayOption}`;
                    }
                )

            // SET interactivity hover 

            this.column.interactivity().hoverMode('by-y');
            this.column.tooltip().valuePrefix('$').displayMode('union');

            // Marker
            let totalSeriesData = dataSet.mapAs({x:0, value:forSetData[0].length - 1}) //get length of first index  
            let totalSeriesForecast = this.column.marker(totalSeriesData);
            totalSeriesForecast.name('Total'); // Rename the totalSeriesForecast
            totalSeriesForecast.normal().type("circle");

            // Add labels to totalSeriesForecast with format 
            totalSeriesForecast.labels()
                .enabled(true)
                .format(
                    function(){
                        return Anychart.format.number(this.value,{
                            groupsSeparator:','
                        });
                    }
                )
                .anchor('center-bottom')
                .offsetY(10)
                .padding(5)
                .background().enabled(true).fill("#FFFFFF"); //Add Background color 


            // SET container id for the chart
            this.column.container(this.$refs.chartContainer).draw();
            this.chart.exports.filename('VS01_'+this.segmentation1ItemsName+'_'+this.topicName+'_Market_Forecast_to_'+this.years[this.years.length -1]+'_by_'+this.segmentationName2); //Change name of exports
        },

        exportXlsx() {
            this.chart.column().saveAsXlsx();

        },
        exportPng() {
            // console.log(this.chart);
            // this.chart.column().saveAsPng({
            //     "width": 360,
            //     "height": 500,
            //     "quality": 0.3,
            //     "filename": 'filename',
            // })


            this.chart.column().saveAsSvg(
                {
                    "width": 360,
                    "height": 500,
                    "filename": 'My Chart SVG'
                }
            )

            // this.chart.saveAsPng({
            //     "width": 360,
            //     "height": 500,
            //     "quality": 0.3,
            //     "filename": this.options.chart.title,
            // });

            // this.chart.saveAsPng({
            //     "width": 360,
            //     "height": 500,
            //     "quality": 0.3,
            //     "filename": 'aaaa',
            // });



        },

        exportToSvgEmit({width,height}){
            this.exportToSvg(width, height)
        },
        exportToSvg(width,height){
            this.column.saveAsSvg(
                {
                    "width":width,
                    "height":height,
                    "filename":'VS01_'+this.segmentation1ItemsName+'_'+this.topicName+'_Market_Forecast_to_'+this.years[this.years.length -1]+'_by_'+this.segmentationName2
                }
            )
        },

        exportSvg() {
            this.column.saveAsSvg(
                {
                    "width": 360,
                    "height": 500,
                    "filename": 'My Chart SVG'
                    // name: topic + segmversus
                    // name: topic + segmversus on title

                }
            )
            // console.log('termino de pasar Algo');

        },

        addChartsForWord(){
            // Obtain toSvg
            // let svgContent = this.column.toSvg();
            // Replace or add width , height on the SVG
            // svgContent = svgContent.replace('<svg', '<svg width="600" height="400"');

            // Clone this.column using JSON
            
            // const clonedColumn = this.column;

            // // change width and height 
            // clonedColumn.width = 600;
            // clonedColumn.height = 400;
            // clonedColumn.resize(600, 400)

            // Procesar el gráfico con el nuevo tamaño
            // const svgContent = clonedColumn.toSvg();


            this.column.width(600);
            this.column.height(400);

            // export Chart to svg with customize size 
            let svg = this.column.toSvg();

            // Convert svg string to an DOM object
            let parser = new DOMParser();
            let svgDoc = parser.parseFromString(svg, 'image/svg+xml');

            // get root <svg >
            let svgElement = svgDoc.documentElement;

            // change width and height 
            svgElement.setAttribute('width', '600');
            svgElement.setAttribute('height', '400');

            // serialize svg to string 
            let serializer = new XMLSerializer();
            let svgString = serializer.serializeToString(svgElement);

            const exportContent = {
                // content: this.column.toSvg(600,400),
                content: svgString,
                name: 'VersusSegmentation '+'Figure 1.1',
                segmentation1: this.segmentationName1,
                segmentation2: this.segmentationName2, 
                order:this.index1
            } ;
            this.$emit("addChartsForExportWord",{exportContent: exportContent, order:exportContent.order})
        },

        endLastStepForExportToWord(){
            this.$emit("finishLoading")
        },
    },
    watch: {
        // options: function (options) {
        //     if (!this.chart && options) {
        //         this.init()
        //     } else {
        //         this.chart.dispose();
        //         this.chart = null;
        //         this.init();
        //     }
        // }
        // 'output': {
        //     async handler(newVal) {
        //         if (newVal) {
        //             // console.log('outputFrom Chart Change', newVal);
        //             if (this.chart) {
        //                 this.column.dispose();
        //                 this.chart = null;
        //                 this.column = null;
        //                 this.init();
        //             }
        //         }
        //     },
        //     deep: true,
        // },
        // 'data':{
        //     async handler(newVal){
        //         if( newVal ){
        //             if (this.chart) {
        //                 this.column.dispose();
        //                 this.chart = null;
        //                 this.column = null;
        //                 this.init();
        //             }
        //         }
        //     }
        // }
    },
    beforeUnmount() {
        if (this.chart) {
            this.column.dispose();
            this.chart = null;
            this.column = null;
        }
    }
    // beforeDestroy() {
    //     if (this.chart) {
    //         this.chart.dispose();
    //         this.chart = null;
    //     }
    // }
}
</script>

<style scoped>
.colorTest {
    background-color: #385D8A;
    background-color: #26CDA1;
}
</style>
